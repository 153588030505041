import React, { useState } from "react";
import { logo } from "../../images";
import styles from "./Header.module.css";
import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";
import { Web3Button } from '@web3modal/react'



const Header = () => {
  const [sidebar, setSidebar] = useState(false);
  const navArray = [
    { navItem: "Home", to: "/" },

  ];
  return (
    <section className={styles.header}>
      <Link to="/" className={styles.logoWrapper}>
        <div className={styles.logoContainer}>
          <img src="https://i.ibb.co/rfyHWcr/logo-adobe-express-1.png" alt="Logo" className={styles.logo} />
          <h5 className={styles.logoText}></h5>
        </div>
      </Link>
      {sidebar ? (
        <MdClose
          className={styles.icon}
          onClick={() => setSidebar((prev) => !prev)}
        />
      ) : (
        <GiHamburgerMenu
          className={styles.icon}
          onClick={() => setSidebar((prev) => !prev)}
        />
      )}
      <div className={styles.navBar}>
        {navArray.map((item, index) => (
          <a href={item.to} key={index} className={styles.navLink}>
            {item.navItem}
          </a>
        ))}
        <div className={styles.calender}>
          <img
            src={`https://cdn-icons-png.flaticon.com/512/3258/3258446.png`}
            alt="calendder"
            className={styles.calenderImage}
          />
          <p className={styles.calenderText}><Web3Button /></p>
        </div>
      </div>
      {sidebar && (
        <div className={styles.sidebar}>
          {navArray.map((item, index) => (
            <a href={item.to} key={index} className={styles.navLink}>
              {item.navItem}
            </a>
          ))}
          <div className={styles.calender}>
            <img
              src={`https://cdn-icons-png.flaticon.com/512/3258/3258446.png`}
              alt="calendder"
              className={styles.calenderImage}
            />
            <p className={styles.calenderText}><Web3Button /></p>
          </div>
        </div>
      )}
    </section>
  );
};

export default Header;

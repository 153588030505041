import React, { useEffect, useState, useRef } from "react";
import { BiCheck, BiPlus, BiMinus } from "react-icons/bi";
import { ethers } from "ethers";
import { abi } from "../Contract/Abi"
import { useWeb3Modal } from "@web3modal/react";

import { erc20 } from "../Contract/Abi"
import { useProvider, useAccount, useSigner } from 'wagmi';
import { useConnectModal } from 'web3modal';
import * as alert from "../Alert/Alert";
import truncateEthAddress from 'truncate-eth-address'
import LoadingSpinner from "./Loader/Loader";
import ProgressBar from "./Progressbar/Progressbar";
import styles from "./GetYourNft.module.css";
import MyCountDown from "./CountDown/CountDown";
import { useParams } from "react-router-dom";
import { Addresses, chainID } from "../Contract/Constants";

const GetYourNft = () => {
  const [mint, setMint] = useState(50);
  const [rate, setRate] = useState(500);
  const [buyToken, setbuyToken] = useState('USDT');
  const [isUSDT, setisUSDT] = useState(false);
  const [isBUSD, setisBUSD] = useState(false);
  const [isUSDC, setisUSDC] = useState(false);
  const [usdtbal, setusdtbal] = useState(0);
  const [busdbal, setbusdbal] = useState(0);
  const [isApproved, setisApproved] = useState(false);

  const [wallet, setwallet] = useState('');
  const [currency, setCurrency] = useState(0);
  const [totalminted, settotalminted] = useState(0);
  const [userMinted, setuserMinted] = useState(0);
  const [unsBal, setUnsBal] = useState(0);

  const [refEarning, setRefEarning] = useState(0);
  const { id } = useParams();
  const [Ref, setRef] = useState("0x0000");

  const isReload = useRef(false);

  const { provider, isReady } = useProvider(chainID.id);
  const [IsdappReady, setisDappReady] = useState(false);
  const [IsdappReady2, setisDappReady2] = useState(false);
  const { data, error, isLoading, refetch } = useSigner(chainID.id)
  const { isOpen, open, close, setDefaultChain } = useWeb3Modal();
  const signer = data;

  const { address, isConnecting, isDisconnected } = useAccount()


  // const etherProvider = new ethers.providers.JsonRpcProvider("https://eth-sepolia.g.alchemy.com/v2/u6oQvorDOys-KGkrHOJzrTXe9iK_lNMS");
  const etherProvider = new ethers.providers.JsonRpcProvider("https://bsc-dataseed1.binance.org");
  const mintContract = new ethers.Contract(Addresses.mintAddress, abi, etherProvider);
  const signerContract = new ethers.Contract(Addresses.mintAddress, abi, data);




  /** */
  const allowance = async () => {
    const erc20Contract = new ethers.Contract(Addresses.usdt, erc20, signer);
    const allo = await erc20Contract.allowance(wallet, Addresses.mintAddress);
    // console.log(allo , "alloc")
    return allo;

  }
  const allowanceBUSD = async () => {
    const erc20Contract = new ethers.Contract(Addresses.busd, erc20, signer);
    const allo = await erc20Contract.allowance(wallet, Addresses.mintAddress);
    // console.log("Addresses.busd", allo)
    return allo;
  }

  const getBalance = async () => {
    console.log("getBalance")
    const busd = new ethers.Contract(Addresses.busd, erc20, etherProvider);
    const usdt = new ethers.Contract(Addresses.usdt, erc20, etherProvider);

    const busdBalance = await busd.balanceOf(wallet);
    const usdtBalance = await usdt.balanceOf(wallet);

    setusdtbal(String(ethers.utils.formatUnits(usdtBalance, 18)));
    setbusdbal(String(ethers.utils.formatUnits(busdBalance, 18)));

    console.log("usdtBalance", String(usdtBalance))

  }

  const ApproveUSDT = async () => {
    try {
      const erc20Signer = new ethers.Contract(Addresses.usdt, erc20, data);
      const tx = await erc20Signer.approve(Addresses.mintAddress, "79299293382937297363737272772277272722378283782938289332");
      const txIndex = await tx.wait();
      if (txIndex.blockNumber) {
        alert.AlertTxnConfirmed();
        ApprovalChecker();
      }
    } catch (e) {
      if (e.code === 4001) {
        alert.AlertRejectedTXN();
      } else if (e) {
        alert.AlertFailedTXN()
      }
    }
  }
  const approveBUSD = async () => {
    try {
      const erc20Signer = new ethers.Contract(Addresses.busd, erc20, data);
      const tx = await erc20Signer.approve(Addresses.mintAddress, "79299293382937297363737272772277272722378283782938289332");
      const txIndex = await tx.wait();
      if (txIndex.blockNumber) {
        alert.AlertTxnConfirmed();
        ApprovalChecker();
      }
    } catch (e) {
      if (e.code === 4001) {
        alert.AlertRejectedTXN();
      } else if (e) {
        alert.AlertFailedTXN()
      }
    }
  }
  const buyUNS = async () => {
    if (mint < 50) {
      alert.AlertMinBuy();
      return;
    }
    try {
      const tx = await signerContract.buy((ethers.utils.parseEther(String(mint))), Ref, currency);
      console.log(tx, "txn")
      const receipt = await tx.wait();
      if (receipt && receipt.blockNumber) {
        alert.AlertTxnConfirmed();
        getUserMinted(wallet);
        setMint(50);
        GetTotalMinted();
      }
    } catch (e) {
      if (e.code === 4001) {
        alert.AlertRejectedTXN();
      } else if (e) {
        alert.AlertFailedTXN()
        console.log(e)
      }
    }
  }

  const connect = async () => {
    open()
    setisDappReady(true);
    ;
  }

  const titleAndFunc = () => {
    if (!address) {
      return {
        title: "Connect Wallet",
        func: connect
      }
    }
    else if (buyToken == 'USDT' & isUSDT == false) {
      return {
        title: "Enable USDT",
        func: ApproveUSDT
      }
    } else if (buyToken == 'BUSD' & isBUSD == false) {
      return {
        title: "Enable BUSD",
        func: approveBUSD
      }
    }

    else {
      return {
        title: `Buy ${mint * rate} AVIVE `,
        func: buyUNS
      }
    }
  }

  const buttonStatus = titleAndFunc();
  const buttonFunc = buttonStatus.func;

  /** */
  const GetTotalMinted = async () => {
    const totalMinted = await mintContract.totalUnsSold();
    settotalminted(parseInt(ethers.utils.formatEther(totalMinted)));
  }
  /** */

  const getRef = async (addr) => {
    try {
      const userRef = await mintContract.referralEarned(ethers.utils.getAddress(addr));
      setRefEarning((ethers.utils.formatEther(userRef)));
    }
    catch (e) {

    }
  }

  const getUserMinted = async () => {
    try {
      const userMinted = await mintContract.contributions(ethers.utils.getAddress(wallet));
      const UM = parseInt(ethers.utils.formatEther(userMinted));
      console.log(UM, "userminted")
      setuserMinted((UM));
      return UM;
    }
    catch (e) {
      //console.log(e);
      setuserMinted(0);
    }
  }

  const getUnsBalance = async () => {
    try {
      const userMinted = await mintContract.unsBalance(ethers.utils.getAddress(wallet));
      const UM = parseInt(ethers.utils.formatEther(userMinted));
      console.log(UM, "userminted")
      setUnsBal((UM));
      return UM;
    }
    catch (e) {
      //console.log(e);
      setUnsBal(0);
    }
  }


  /** */


  /** */

  const ApprovalChecker = async () => {
    getBalance();
    try {
      if (buyToken == 'USDT') {

        const approve = await allowance();
        if (String(approve) < "1") {
          setisUSDT(false)
          getBalance();
          console.log(String(approve), "this is Addresses.usdt runninf");
        } else if (String(approve) > "1") {
          // console.log(approve ,"this is Addresses.usdt runninf")
          setisUSDT(true);
        }
      } else if (buyToken == 'BUSD') {
        const approve = await allowanceBUSD();
        if (parseInt(approve) < "79299293382937297363737272772277272722378283782938") {
          setisBUSD(false);
        } else if (parseInt(approve) > "79299293382937297363737272772277272722378283782938") {
          setisBUSD(true);
        }
      }
    } catch (e) {
      // console.log(e);
    }

  }


  const setReferrer = async () => {
    if (ethers.utils.isAddress(id) == true) {
      setRef(id);
    } else if (!id) {
      setRef("0x6747044B705ad59A12D46FC291dA75734769Ee98");
    } else if (ethers.utils.isAddress(id) == false) {
      setRef("0x6747044B705ad59A12D46FC291dA75734769Ee98");
    }
    if (userMinted == 5) {
      setMint(0)
    }

    const userMintedValue = await getUserMinted(wallet);

    userMintedValue && setuserMinted(parseInt(userMintedValue));


  }

  const init = () => {
    GetTotalMinted();

    getRef(wallet);
    getUserMinted();
    getUnsBalance();
    ApprovalChecker();
    titleAndFunc();
    setwallet(address);
    setReferrer();
  }

  useEffect(() => {
    init();
  }, [init, userMinted, currency,]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch(chainID.id);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const [minting, setMinting] = useState(false);

  const displayBalance = () => {
    if (buyToken == "USDT") {
      return usdtbal;
    } else if (buyToken == "BUSD") {
      return busdbal;
    }
  }

  const balance = displayBalance();

  const increase = () => {
    if (mint + userMinted < 5000) {
      setMint((prev) => prev + 50);
    }
    if (mint + userMinted == 5000) {
      alert.AlertMaxBuy()
    }
  };
  const decrease = () => {
    if (mint > 50) {
      setMint((prev) => prev - 50);
    }
    if (mint == 50) {
      alert.AlertMinBuy();
    }
  };
  const currencies = ["usdt", "busd"];
  return (
    <section className={styles.getYourNft}>
      <div className={styles.headingAndLive}>
        <h3 className={styles.heading}>Buy AVIVE</h3>
        <div className={styles.timerContainer}>
          <div className={styles.timer}>
            <span className={styles.circle} />
            <span className={styles.live}>Live</span>
            {/* <span className={`${styles.live} ${styles.time}`}>
              {" "}
              <MyCountDown dayCount="Apr 29 2023 05:30:01" />{" "}
            </span> */}
          </div>
        </div>
      </div>
      <div className={styles.mintingWrapper}>
        {/* <p className={styles.mintingDate}>
          <span className={styles.key}> Presale date : </span>
          <span className={styles.value}>
            Mar 17 2:00am - Apr 29 2:00am UTC
          </span>
        </p>{" "} */}
        <p className={styles.mintingDate}>
          <span className={styles.key}> Price : </span>
          <span className={styles.value}> 1 USDT =
            {rate} AVIVE <span className={styles.key}></span>
          </span>
        </p>
        <p className={styles.mintingDate}>
          <span className={styles.key}> Referrer : </span>
          <span className={styles.value}>
            {truncateEthAddress(Ref)} <span className={styles.key}></span>
          </span>
        </p>
        {/* <p className={styles.mintingDate}>
          <span className={styles.key}> My AVIVE Balance : </span>
          <span className={styles.value}>
            {unsBal} AVIVE <span className={styles.key}></span>
          </span>
        </p> */}
        <p className={styles.mintingDate}>
          <span className={styles.key}> My Referral Earnings : </span>
          <span className={styles.value}>
            {refEarning} AVIVE <span className={styles.key}></span>
          </span>
        </p>
        <p className={styles.mintingDate}>
          <span className={styles.key}> Minimum Purchase : </span>
          <span className={styles.value}>
            {"50"} {buyToken} <span className={styles.key}></span>
          </span>
        </p>


      </div>
      <h5 className={styles.title}>
        Enter Amount{" "}
        <span className={styles.maxMint}> Available: {balance}  </span>
      </h5>
      <div className={styles.mintContainer}>
        <BiMinus
          onClick={decrease}
          style={{ userSelect: 'none' }}
          className={`${styles.mint} ${mint === 0 && styles.mintDeactive}`}
        />
        {/* <span className={styles.mint} style={{ userSelect: 'none' }}>{mint}</span>{" "} */}
        <input type="text" inputMode="numeric" className={styles.mint} style={{ userSelect: 'none' }} value={mint} onChange={(e) => {
          if (e.target.value > 5000 - userMinted) {
            alert.AlertMaxBuy()
          } else {
            setMint(e.target.value)
          }
        }} />
        <BiPlus
          style={{ userSelect: 'none' }}
          onClick={increase}
          className={`${styles.mint} ${mint === 5000 && styles.mintDeactive}`}
        />
      </div>
      <h5 className={styles.title}>Select Purchase Currency? </h5>
      <div className={styles.currencyContainer}>
        {currencies.map((item, i) => (
          <div
            className={`${styles.currency} ${currency === i && styles.currencyActive
              }`}
            key={i}
            onClick={async () => {
              if (i == 0) {
                setbuyToken("USDT");
              } else if (i == 1) {
                setbuyToken("BUSD");
              }
              // getUserMinted();
              ApprovalChecker();
              titleAndFunc();
              setMinting(true);
              setTimeout(() => {
                setMinting(false);
              }, 1500);
              setwallet(address)
              console.log(address)
              console.log(buyToken);
              console.log(i);
              setCurrency(i)
            }

            }
          >
            {currency === i && <BiCheck className={styles.ok} />} {item}
          </div>
        ))}
      </div>
      <button
        className={styles.loader}
        onClick={() => {
          buttonFunc();
          setMinting(true);
          setTimeout(() => {
            setMinting(false);
          }, 5000);
        }}
      >
        {minting ? (
          <LoadingSpinner />
        ) : (
          <span className={styles.minting}>{buttonStatus.title}</span>
        )}
      </button>
      <div className={styles.transaction}>
        <p className={styles.transactionKey}>Network </p>
        <p className={`${styles.transactionValue} ${styles.transactionKey}`}>
          BEP20
        </p>
      </div>
      {/* <div className={styles.transaction}>
        <p className={styles.transactionKey}>Transaction Fee</p>
        <p className={`${styles.transactionValue} ${styles.transactionKey}`}>
          ~0.07$ BNB
        </p>
      </div> */}
      <div className={`${styles.transaction} ${styles.transactionBottom}`}>
        <p className={styles.transactionKey}>You Will Receive</p>
        <p className={`${styles.transactionValue} ${styles.transactionKey}`}>
          {mint * rate} AVIVE
        </p>
      </div>{" "}
      <div className={styles.progressAndMinted}>
        <ProgressBar bgcolor="#404042" progress={userMinted} max={5000} height={10} />
        <div className={styles.mintedContainer}>
          <p className={styles.minted}>My Purchase Allocation</p>
          <p className={styles.minted}>{userMinted} / 5000</p>
        </div>
      </div>{" "}
      {/* <div className={styles.progressAndMinted}>
        <ProgressBar bgcolor="#404042" progress={totalminted} max={100000} height={10} />
        <div className={styles.mintedContainer}>
          <p className={styles.minted}>Total AVIVE Sold</p>
          <p className={styles.minted}>{totalminted} / 100000</p>
        </div>
      </div> */}
    </section>
  );
};

export default GetYourNft;

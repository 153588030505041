import React from "react";
import styles from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <section className={styles.heroSection}>
      {/*   <div className={styles.imageContainer}>
        <img src={aliens} alt="Aliens" className={styles.image} />
  </div> */}
      <h2 className={styles.heading}>AVIVE Token</h2>
      <div className={styles.whiteList}>
        <span className={styles.circle} />
        <span>Sales is Live</span>
      </div>
      <p className={styles.text}>
        Avive token is a decentralized cryptocurrency that operates on the Binance Smart Chain (BSC) network. As a decentralized token, Avive does not have a central authority controlling its operations, and is instead managed by its community of users through a consensus mechanism.
        <br /><br />
        Avive aims to provide users with a secure, fast, and low-cost means of conducting transactions and storing value. It leverages the capabilities of the BSC network to achieve these goals, and offers a range of features and benefits including fast transaction speeds, low fees, and decentralized governance.      </p>
    </section>
  );
};

export default HeroSection;

import React from "react";
import styles from "./SampleDrops.module.css";
import { o1, o2, o3, o4 } from "../../images";

const SampleDrops = () => {
  const data = [
    { img: "https://cryptologos.cc/logos/tether-usdt-logo.png", name: "USDT", id: "1" },
    { img: "https://altcoinsbox.com/wp-content/uploads/2023/01/black-binance-usd-busd-logo.webp", name: "BUSD", id: "2" },

  ];
  return (
    <section className={styles.sampledrops}>
      <h4 className={styles.title}>Buy AVIVE with </h4>
      <div className={styles.wrapper}>
        {data.map((item, index) => (
          <div className={styles.imageContainer} key={index}>
            <img src={item.img} alt="#" className={styles.image} />

            <p className={styles.name}>
              {item.name}
            </p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default SampleDrops;
